/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import Image from "next/image"
import { IconId } from "@daybridge/client-api"
import { I } from "@daybridge/icons"
import { ButtonSize, Button, ButtonVariant } from "@daybridge/components-core"
import { IdentityProvider, useAuth } from "@daybridge/auth"
import { SignUpLayout } from "../SignUpLayout"

const SelectProviderStep: React.FC = React.memo(() => {
  const { signInWith } = useAuth()
  return (
    <SignUpLayout>
      {/* Gradient background */}
      <div className="absolute rounded-t-3xl overflow-hidden pointer-events-none">
        <div className="dark:hidden relative">
          <Image
            alt="Gradient"
            src="/assets/signup/gradient-signup.png"
            width={384}
            height={225}
          />
        </div>
        <div className="hidden dark:block relative">
          <Image
            alt="Gradient"
            src="/assets/signup/gradient-signup-dark.png"
            width={384}
            height={286}
          />
        </div>
      </div>
      <div className="px-6 pb-8 pt-10 relative z-10">
        <div className="text-lg text-high-contrast text-center font-semibold">
          Sign in to Daybridge
        </div>
        <div
          className="
            mt-2 mb-10 
            text-base text-low-contrast text-center
          "
        >
          We&apos;ll create an account if you <br />
          don&apos;t have one yet.
        </div>
        <div className="space-y-3">
          <Button
            block
            variant={ButtonVariant.OutlineAlpha}
            size={ButtonSize.Medium}
            onPress={() => signInWith(IdentityProvider.Google)}
          >
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 mr-3 flex-shrink-0"
            >
              <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                <path
                  fill="#4285F4"
                  d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                />
                <path
                  fill="#34A853"
                  d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                />
                <path
                  fill="#FBBC05"
                  d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                />
                <path
                  fill="#EA4335"
                  d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                />
              </g>
            </svg>
            Continue with Google
          </Button>
          <Button
            block
            variant={ButtonVariant.OutlineAlpha}
            size={ButtonSize.Medium}
            onPress={() => signInWith(IdentityProvider.Twitter)}
          >
            <I
              type={IconId.Twitter}
              className="w-4 h-4 mr-3 flex-shrink-0 text-[#1DA1F2]"
            />
            Continue with Twitter
          </Button>
          <Button
            block
            variant={ButtonVariant.OutlineAlpha}
            size={ButtonSize.Medium}
            onPress={() => signInWith(IdentityProvider.Apple)}
          >
            <I type={IconId.Apple} className="w-4 h-4 mr-3 flex-shrink-0" />
            Continue with Apple
          </Button>
        </div>
      </div>
    </SignUpLayout>
  )
})

SelectProviderStep.displayName = "SelectProviderStep"
export default SelectProviderStep
