import { useAuth, FirebaseError } from "@daybridge/auth"
import { error as errorToast } from "@daybridge/toast"
import { captureException } from "@sentry/nextjs"
import { useRouter } from "next/router"
import { useEffect } from "react"
import SplashIfMobile from "../components/SplashIfMobile"
import SelectProviderStep from "../features/sign-up/components/steps/SelectProviderStep"

export const LoginPage = () => {
  const { user, error } = useAuth()

  // Make sure there's a returnTo path set. This is used on the way
  // back to the app after a successful login.
  const router = useRouter()
  useEffect(() => {
    if (!router.query["returnTo"]) {
      void router.replace("/login?returnTo=%2F")
    }
  }, [router])

  // Handle errors.
  // See error codes: https://firebase.google.com/docs/reference/js/v8/firebase.auth.Error
  useEffect(() => {
    if (!error) return

    // Don't handle the error when the users cancels manually.
    if ((error as FirebaseError).code === "auth/user-cancelled") return

    // Handle the error that occurs if the browser does not support web storage
    // or if the user disables them (usually that means the user is in
    // incognito mode).
    if ((error as FirebaseError).code === "auth/web-storage-unsupported") {
      errorToast({
        title: "Problem signing in",
        description: "Please exit incognito mode or enable cookies to log in.",
      })
      return
    }

    // For all other errors, show a toast and send it to Sentry.
    errorToast({
      title: "Problem signing in",
      description: "Sorry, we couldn't sign you in. Please try again.",
    })

    captureException(error)
  }, [error])

  // This shouldn't happen because the AuthCoordinator should take
  // care of it, but if for some reason a logged-in user is sent to
  // this screen, send them home.
  if (user) {
    void router.replace("/")
    return <div />
  }

  const redirectToEventPage =
    typeof router.query["returnTo"] === "string" &&
    router.query["returnTo"].startsWith("/events")

  return (
    <SplashIfMobile skipMobileSplash={redirectToEventPage}>
      <SelectProviderStep />
    </SplashIfMobile>
  )
}

// Skip authentication
LoginPage.skipAuth = true

// Skip default mobile splash screen – we use our own logic here to
// only allow users to log in if they are coming from an event page.
LoginPage.skipMobileSplash = true

export default LoginPage
