import React from "react"
import { Logo } from "@daybridge/components-core"

interface SignUpLayoutProps {
  children?: React.ReactNode
}

export const SignUpLayout: React.FC<SignUpLayoutProps> = React.memo(
  (props: SignUpLayoutProps) => {
    return (
      <div
        className="
          min-h-full py-10 md:py-24 px-3 md:px-0
          flex flex-col items-center justify-center 
          bg-background
        "
      >
        <div className="w-40 text-primary-body dark:text-white mb-10">
          <Logo hasWordmark />
        </div>
        <div
          className="
            w-full md:w-96
            bg-surface shadow-sm rounded-3xl
            relative
            ring-1 ring-inset ring-black/5 dark:ring-white/5
          "
        >
          <div className="relative z-10">{props.children}</div>
        </div>
      </div>
    )
  },
)

SignUpLayout.displayName = "SignUpLayout"
